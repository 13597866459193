import React, { useState, useContext, useEffect } from "react";
import MainNavigation from "../../shared/menu/MainNavigation";
import { Link, useHistory } from "react-router-dom";
import {
  Layout,
  Space,
  Table,
  Result,
  Form,
  Input,
  Button,
  Spin,
  Alert,
} from "antd";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { Helmet } from "react-helmet";
import axios from "axios";
const { Content, Sider } = Layout;

function Campaign() {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [profile, setProfile] = useState([]);
  const [ShowSuccessMessage, setShowSuccessMessage] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}profile/${auth.userId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        setProfile(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile();
  }, [auth.token, auth.userId]);

  useEffect(() => {
    const fetchCampaigns = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}get-campaigns/${auth.userId}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        setCampaigns(response.data.campaigns);
        console.log("campaings", response.data);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
      setLoading(false);
    };

    fetchCampaigns();
  }, [auth.userId, auth.token]);

  const handleCampaignAnalytics = (campaignId) => {
    history.push(`/campaign-analytics/${campaignId}`); // Navigate to the campaign analytics page
  };

  const dataSource = campaigns.map((campaign) => ({
    key: campaign._id,
    campaignName: campaign.campaignName,
    templateName: campaign.templateName,
    contacts: campaign.contacts.length, // Assuming contacts is an array of strings
    action: (
      <Button
        type="defult"
        style={{ color: "blue" }}
        onClick={() => handleCampaignAnalytics(campaign._id)}
      >
        View Analytics
      </Button>
    ),
  }));

  const columns = [
    {
      title: "Campaign Name",
      dataIndex: "campaignName",
      key: "campaignName",
    },
    {
      title: "Template Name",
      dataIndex: "templateName",
      key: "templateName",
    },
    {
      title: "Message Sent",
      dataIndex: "contacts",
      key: "contacts",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  return (
    <Layout hasSider>
      {loading ? <Spin /> : !loading}
      <Helmet>
        <meta charSet="utf-8" />
        <title>WhatsApp Campaigns | C-Edge BigRadar</title>
        <link
          rel="canonical"
          href="https://app.billsubmit.com/create-company"
        />
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div className="main-heading">
              <div>
                <h2>WhatsApp Campaign</h2>
              </div>
              <div>
                {profile.outBoundCredits >= 0 ? (
                  <Link to="/create-campaign">
                    <Button
                      type="primary"
                      size="large"
                      style={{ borderRadius: "4px", marginLeft: "20px" }}
                      // disabled
                    >
                      Create New Campaign
                    </Button>
                  </Link>
                ) : null}
              </div>
            </div>
            {profile.outBoundCredits === 0 ? (
              <Alert
                message="Whatsapp Outbound Credits exhausted. Please contact your C-Edge Account Manager to recharge."
                type="error"
              />
            ) : null}
            <div style={{ marginTop: "20px" }}>
              <Table
                dataSource={dataSource}
                columns={columns}
                rowKey={(record) => record.id}
                size="middle"
                pagination={{ pageSize: 25 }}
              />
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Campaign;
