import React, { useState, useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom"; // Import Link from react-router-dom
import MainNavigation from "../../shared/menu/MainNavigation";
import {
  Layout,
  Table,
  Spin,
  Input,
  Row,
  Col,
  Statistic,
  message,
  notification,
  Form,
  Button,
} from "antd";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import axios from "axios";
import { Helmet } from "react-helmet";

const { Content, Sider } = Layout;
const { Search } = Input;

function EditContact() {
  const { id } = useParams();
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { isLoading, error, sendRequest } = useHttpClient();
  const [analyticsData, setAnalyticsData] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    state: "",
    city: "",
    pincode: "",
  });

  useEffect(() => {
    const fetchContactData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}contact-detail/${id}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );

        console.log(response.data[0]);
        setFormData(response.data[0]);
      } catch (error) {
        console.error("Error fetching campaign analytics data:", error);
      }
      setLoading(false);
    };

    fetchContactData();
  }, [auth.userId, id, auth.token]);

  const handleFormSubmit = async () => {
    try {
      // Send a request to update student details
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}update-contact/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      notification.open({ message: "Student details updated!" });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout hasSider>
      {loading && <Spin />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>WhatsApp Campaigns | C-Edge BigRadar</title>
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <h2 style={{ margin: 0, marginBottom: 20 }}>Edit Contacts</h2>

            <Form layout="vertical" onFinish={handleFormSubmit}>
              <Form.Item label="Name">
                <Input
                  name="name"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      name: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item label="Phone Number">
                <Input
                  name="email"
                  value={formData.phoneNumber}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      phoneNumber: e.target.value,
                    })
                  }
                />
              </Form.Item>

              <Form.Item label="State">
                <Input
                  name="state"
                  value={formData.state}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      state: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item label="City">
                <Input
                  name="city"
                  value={formData.city}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      city: e.target.value,
                    })
                  }
                />
              </Form.Item>

              <Form.Item label="Pincode">
                <Input
                  name="pincode"
                  value={formData.pincode}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      pincode: e.target.value,
                    })
                  }
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default EditContact;
