import React, { useEffect, useState, useContext } from "react";
import MainNavigation from "../../shared/menu/MainNavigation";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { ConnectButton } from "360dialog-connect-button";
import axios from "axios";

import moment from "moment";
import { UserOutlined } from "@ant-design/icons";
import {
  Layout,
  Space,
  Table,
  Tag,
  Input,
  DatePicker,
  Button,
  Select,
  Alert,
  Tabs,
  Row,
  Col,
  Avatar,
  Divider,
} from "antd";
const { Content, Sider } = Layout;
const { TabPane } = Tabs;

// Ensure that 'FB' is defined
// const FB = window.FB;

function Dashboard() {
  const auth = useContext(AuthContext);
  const [businessProfiles, setBusinessProfiles] = useState([]);
  const [bills, setBills] = useState([]);
  const [project, setProject] = useState();
  const [loading, setLoading] = useState();
  const [embeddedSignupUrl, setEmbeddedSignupUrl] = useState(null);

  useEffect(() => {
    const fetchBills = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/accounts/${auth.userId}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        const bills = response.data.map((row) => ({
          id: row._id,
          name: row.name,
          wanumber: row.wanumber,
          email: row.email,
          companyName: row.companyName,
          status: row.status,
          date: moment(row.date).format("YYYY-MM-DD"),
        }));

        // Sort the bills by date in descending order
        bills.sort((a, b) => new Date(b.date) - new Date(a.date));

        setBills(bills);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchBills();
  }, [auth.userId, auth.token]);

  const handleCallBack = async (callbackObject) => {
    console.log("client Id: " + callbackObject.client);
    console.log("channel Ids: " + callbackObject.channels);
    const stateId = new URLSearchParams(window.location.search).get("state");
    if (callbackObject.revokedChannels) {
      console.log("revoked Channel Id: " + callbackObject.revokedChannels);
    }
    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "update-account",
        {
          clientId: callbackObject.client,
          channelId: callbackObject.channels,
          stateId: stateId,
          creator: auth.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
        }
      );
      console.log("Data saved successfully");
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      title: "Account Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link
          to={`/bills/details/${record.id}`}
          style={{ textDecoration: "none" }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "WhatsApp Number",
      dataIndex: "wanumber",
      key: "wanumber",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text) => (
        <Tag color={text === "Connected" ? "green" : "red"}>{text}</Tag>
      ),
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <ConnectButton
    //         partnerId={"46C3maPA"}
    //         callback={handleCallBack}
    //         label="Create WABA Account"
    //         style={{
    //           color: "black",
    //           backgroundColor: "white",
    //           border: "1px solid #000",
    //           padding: "8px",
    //           borderRadius: "4px",
    //           cursor: "pointer",
    //         }}
    //         queryParameters={{
    //           email: record.email,
    //           name: record.name,
    //           state: record.id,
    //           partner: record.id,
    //           redirect_url: "https://wa-bigradar.web.app/",
    //         }}
    //       />

    //       {/* <Button type="primary" onClick={handleFacebookLogin}>
    //         Connect With Facebook
    //       </Button> */}
    //     </Space>
    //   ),
    // },
  ];

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}whatsapp/settings/businesses/${auth.userId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        const userData = response.data;

        console.log(userData); // Check the value

        // Set the retrieved data in the formData state
        setLoading(false);

        setBusinessProfiles(userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [auth.token, auth.userId]);

  // https://hub.360dialog.com/dashboard/app/<partner-id>/permissions

  const businessId = businessProfiles[0]?.businessId;
  console.log(businessId, "busine");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}whatsapp/settings/project/${businessId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        const userData = response?.data;

        console.log(userData, "projext"); // Check the value

        // Set the retrieved data in the formData state
        setProject(userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [auth.token, businessId]);

  const fetchEmbeddedSignupUrl = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}whatsapp/settings/generate-waba-link`,
        {
          businessId: businessProfiles[0]?.businessId,
          projectId: project.id,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      );
      setEmbeddedSignupUrl(response.data.embeddedSignupUrl);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (businessProfiles.length > 0) {
      fetchEmbeddedSignupUrl();
    }
  }, [businessProfiles, fetchEmbeddedSignupUrl]);

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <h1>WhatsApp Business Profile</h1>
              {bills.length === 0 && (
                <Link to="/create-waaccount">
                  <Button
                    size="large"
                    type="primary"
                    style={{ backgroundColor: "#000", border: "none" }}
                  >
                    Create Business profile
                  </Button>
                </Link>
              )}
            </div>
            <Table
              style={{ marginTop: "0px" }}
              dataSource={bills}
              columns={columns}
              rowKey={(record) => record.id}
              size="middle"
              pagination={{ pageSize: 25 }}
            />
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h1>WhatsApp Business Profile</h1>
              {businessProfiles.length === 0 && (
                <Link to="/create-waaccount">
                  <Button
                    size="large"
                    type="primary"
                    style={{ backgroundColor: "#000", border: "none" }}
                  >
                    Create Business profile
                  </Button>
                </Link>
              )}
            </div>

            {businessProfiles.length === 0 && (
              <Alert
                message="Before starting integration!"
                type="info"
                style={{ marginBottom: "30px", marginTop: "30px" }}
                description={
                  <>
                    <li>
                      Make sure you have a new or unused number for your
                      organization’s WhatsApp Business
                    </li>
                    <li>
                      Keep your business phone number handy for OTP
                      verification.
                    </li>
                    <li>Keep your Facebook Business Manager account ready.</li>
                  </>
                }
              />
            )}

            <div>
              {businessProfiles.length > 0 ? (
                <Row align="middle">
                  <Col span={24} style={{ marginTop: "20px" }}>
                    <Col span={2}>
                      <Avatar size={64} icon={<UserOutlined />} />
                    </Col>
                    <Col style={{ marginTop: "20px" }}>
                      <h2 style={{ margin: 0 }}>
                        {businessProfiles[0]?.companyName}
                      </h2>
                      <Row style={{ marginTop: "10px" }}>
                        <Col span={6}>
                          <p style={{ margin: 0 }}>Contact Number</p>
                          <h4 style={{ margin: 0, marginTop: 6 }}>
                            {businessProfiles[0]?.contactNumber}
                          </h4>
                        </Col>
                        <Col span={8}>
                          <p style={{ margin: 0 }}>Admin Email</p>
                          <h4 style={{ margin: 0, marginTop: 6 }}>
                            {businessProfiles[0]?.email}
                          </h4>
                        </Col>
                        <Col span={8}>
                          <p style={{ margin: 0 }}>Display Name</p>
                          <h4 style={{ margin: 0, marginTop: 6 }}>
                            {businessProfiles[0]?.displayName}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>
              ) : (
                <p>
                  No business profile found. Please create one to start WhatsApp
                  integration.
                </p>
              )}
            </div>

            <div>
              <Row
                align="middle"
                style={{
                  backgroundColor: "#f9f9f9",
                  padding: "20px",
                  marginTop: "40px",
                }}
              >
                <Col span={8}>
                  <p style={{ margin: 0 }}>Project Name</p>
                  <h4 style={{ margin: 0, marginTop: "4px" }}>
                    {project?.name}
                  </h4>
                </Col>
                <Col span={8}>
                  <p style={{ margin: 0 }}>WhatsApp Linked Number</p>
                  <h4 style={{ margin: 0, marginTop: "4px" }}>
                    E.g {project?.waNumber}
                  </h4>
                </Col>
                <Col span={8}>
                  <p style={{ margin: 0 }}>Status</p>
                  <Tag
                    color={project?.status === "active" ? "green" : "red"}
                    style={{ marginTop: "4px" }}
                  >
                    {project?.status}
                  </Tag>
                </Col>
              </Row>
            </div>
            <Row style={{ marginTop: "40px" }}>
              <Tabs defaultActiveKey="1" style={{ width: "100%" }}>
                <TabPane tab="Setup" key="1">
                  <Row align="middle">
                    <Col span={20}>
                      <h1>Connect With Facebook</h1>
                    </Col>
                    <Col span={4}>
                      {embeddedSignupUrl && (
                        <a
                          href={embeddedSignupUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            size="large"
                            type="primary"
                            style={{
                              backgroundColor: "#000",
                              border: "none",
                            }}
                          >
                            Connect With Facebook
                          </Button>
                        </a>
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Details" key="2">
                  <Col span={24}>
                    <h1>WhatsApp Channel</h1>
                  </Col>
                  <Row style={{ marginTop: "10px" }}>
                    <Col span={8}>Phone Number:</Col>
                    <Col span={16}>{project?.waNumber}</Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col span={8}>Display Name:</Col>
                    <Col span={16}>{project?.waDisplayName}</Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col span={8}>Quality Rating:</Col>
                    <Col span={16}>{project?.waQualityRating}</Col>
                  </Row>
                </TabPane>
                <TabPane tab="Analytics" key="3">
                  <Col span={24}>
                    <h1>Your Analytics</h1>
                  </Col>
                </TabPane>
                <TabPane tab="Templates" key="4">
                  Content of Tab Pane 1
                </TabPane>
                <TabPane tab="Profile" key="5">
                  Content of Tab Pane 1
                </TabPane>
              </Tabs>
            </Row>{" "}
            */}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
