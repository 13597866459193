import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import Dashboard from "./components/dashboard/pages/Dashboard";
import DashboardDemo from "./components/dashboard/pages/DashboardDemo";
// import Campaign from "./components/campaigns/pages/Campaign";
import Auth from "./components/auth/Auth";
import { AuthContext } from "./components/shared/context/auth-context";
import { useAuth } from "./components/shared/hooks/auth-hook";
import WaAccount from "./components/waAccount/WaAccount";
import Campaign from "./components/campaigns/pages/Campaign";
import CreateCampaign from "./components/campaigns/pages/CreateCampaign";
import Credits from "./components/credits/pages/Credits";
import Templates from "./components/templates/Templates";
import CreateTemplate from "./components/templates/CreateTemplate";
import AllContacts from "./components/contacts/pages/AllContacts";
import Analytics from "./components/analytics/pages/Analytics";
import Profile from "./components/profile/pages/Profile";
import CampaignAnalytics from "./components/campaigns/pages/CampaignAnalytics";
import EditContact from "./components/contacts/pages/EditContact";

function App() {
  const { token, login, logout, userId, isLoading } = useAuth();
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const history = useHistory(); // Initialize the history object

  let routes;

  useEffect(() => {
    if (!isLoading) {
      setIsCheckingAuth(false);
    }
  }, [isLoading]);

  if (isLoading || isCheckingAuth) {
    routes = <div>Loading...</div>;
  } else if (token) {
    routes = (
      <>
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route exact path="/testfb">
            <DashboardDemo />
          </Route>
          <Route exact path="/create-waaccount">
            <WaAccount />
          </Route>
          <Route exact path="/campaigns">
            <Campaign />
          </Route>
          <Route exact path="/templates">
            <Templates />
          </Route>
          <Route exact path="/create-campaign">
            <CreateCampaign />
          </Route>
          <Route exact path="/create-template">
            <CreateTemplate />
          </Route>
          <Route exact path="/contacts">
            <AllContacts />
          </Route>
          <Route exact path="/analytics">
            <Analytics />
          </Route>
          <Route exact path="/credits">
            <Credits />
          </Route>
          <Route exact path="/profile">
            <Profile />
          </Route>

          <Route exact path="/edit-contact/:id">
            <EditContact />
          </Route>

          <Route exact path="/campaign-analytics/:campaignId">
            <CampaignAnalytics />
          </Route>

          <Redirect to="/" />
        </Switch>
      </>
    );
  } else {
    routes = (
      <Switch>
        <Route exact path="/auth">
          <Auth />
        </Route>
        <Redirect to="/auth" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
      }}
    >
      <Router>{routes}</Router>
    </AuthContext.Provider>
  );
}

export default App;
