import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Input, Alert } from "antd";
import { AuthContext } from "../shared/context/auth-context";

import { useHistory } from "react-router-dom";
import doc from "../../img/docWa.png";
import video from "../../img/videoWa.png";
import location from "../../img/locationWa.png";
import axios from "axios";

function PreviewPopup({ formData, onClose }) {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [variableValues, setVariableValues] = useState({});
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [headerImageUrl, setHeaderImageUrl] = useState(
    formData.headerImageUrl || ""
  );
  const [videoUrl, setVideoUrl] = useState(
    formData.headerType === "video" ? headerImageUrl : ""
  );
  const [documentUrl, setDocumentUrl] = useState(
    formData.headerType === "document" ? headerImageUrl : ""
  );
  const [locationUrl, setLocationUrl] = useState(
    formData.headerType === "location" ? headerImageUrl : ""
  );

  const handleClose = () => {
    onClose();
    history.push("/templates"); // Navigate to the "/templates" URL
  };

  // Parse messageBody and identify variables
  const variableRegex = /{{(\d+)}}/g;
  const variables = [];
  let match;
  while ((match = variableRegex.exec(formData.messageBody)) !== null) {
    const variableIndex = parseInt(match[1]);
    if (!variables.includes(variableIndex)) {
      variables.push(variableIndex);
    }
  }

  // Create input fields for each variable
  const inputFields = variables.map((variableIndex) => (
    <>
      <p style={{ margin: 0, marginTop: 8 }}>{`Variable ${variableIndex}`}</p>
      <Input
        key={variableIndex}
        value={variableValues[variableIndex] || ""}
        onChange={(e) => handleVariableChange(variableIndex, e.target.value)}
        placeholder={`Variable ${variableIndex}`}
        size="large"
        style={{ width: "100%", marginTop: "8px" }}
      />
    </>
  ));

  // Update the preview text with variable values or leave variables intact
  const renderMessageWithVariables = () => {
    let messageWithVariables = formData.messageBody;

    const variableRegex = /{{(\d+)}}/g;
    messageWithVariables = messageWithVariables.replace(
      variableRegex,
      (match, variableIndex) => {
        const variableValue =
          variableValues[variableIndex] || `{{${variableIndex}}}`;
        return variableValue;
      }
    );

    return messageWithVariables;
  };

  // Function to update variable values
  const handleVariableChange = (variableIndex, value) => {
    setVariableValues((prevValues) => ({
      ...prevValues,
      [variableIndex]: value,
    }));
  };

  const handleFieldChange = (fieldName, value) => {
    if (fieldName === "headerImageUrl") {
      setHeaderImageUrl(value);
      // Update the specific URL state based on header type
      switch (formData.headerType) {
        case "video":
          setVideoUrl(value);
          break;
        case "document":
          setDocumentUrl(value);
          break;
        case "location":
          setLocationUrl(value);
          break;
        default:
          break;
      }
    } else {
      setVariableValues((prevValues) => ({
        ...prevValues,
        [fieldName]: value,
      }));
    }
  };

  const handleFormSubmit = async () => {
    try {
      // Check if any input field is empty
      setLoading(true);
      const isAnyFieldEmpty = Object.values(variableValues).some(
        (value) => !value
      );

      if (isAnyFieldEmpty) {
        // Display an error message or prevent form submission
        console.error("Please fill in all example fields.");
        return;
      }

      let headerTypeValue;

      // Determine the appropriate headerType value based on formData.headerType
      switch (formData.headerType) {
        case "image":
          headerTypeValue = headerImageUrl;
          break;
        case "video":
          headerTypeValue = videoUrl;
          break;
        case "document":
          headerTypeValue = documentUrl;
          break;
        case "location":
          headerTypeValue = locationUrl;
          break;
        default:
          headerTypeValue = "";
          break;
      }

      const dataToSend = {
        formData,
        variableValues,
        headerType: headerTypeValue,
        creator: auth.userId,
      };

      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "create-template",
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
        }
      );

      console.log("Response from the server:", response.data);

      if (response.data.error) {
        // Display the error message
        setErrorMessage(response.data.error);
      } else {
        // Show a success message
        setLoading(false);
        setSuccessMessage("Template created successfully!");
        // onClose();
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      setErrorMessage("Error submitting data:");
    }
  };

  return (
    <Modal
      title="Template Preview"
      width={"60%"}
      visible={true}
      onCancel={onClose}
      footer={[
        // <Button key="back" onClick={handleClose}>
        //   Close
        // </Button>,
        !(successMessage || errorMessage) && (
          <Button
            key="submit"
            type="primary"
            onClick={handleFormSubmit}
            loading={loading}
          >
            Submit
          </Button>
        ),
      ]}
    >
      {/* Success message */}
      {successMessage && (
        <Alert message={successMessage} type="success" showIcon />
      )}

      {/* Error message */}
      {errorMessage && <Alert message={errorMessage} type="error" showIcon />}

      {successMessage || errorMessage ? null : (
        <div style={{ display: "flex", marginTop: "20px" }}>
          <div style={{ width: "50%", padding: "0px 20px 00px 0px" }}>
            <h4 style={{ margin: 0 }}>Example Values</h4>
            {formData.headerType === "image" && (
              <>
                <p style={{ margin: 0, marginTop: 8 }}>Image Url</p>
                <Input
                  value={headerImageUrl}
                  size="large"
                  onChange={(e) =>
                    handleFieldChange("headerImageUrl", e.target.value)
                  }
                  placeholder="Image URL"
                  style={{ width: "100%", marginTop: "8px" }}
                />
              </>
            )}

            {formData.headerType === "video" && (
              <>
                <p style={{ margin: 0, marginTop: 8 }}>Video URL</p>
                <Input
                  value={headerImageUrl}
                  size="large"
                  onChange={(e) =>
                    handleFieldChange("headerImageUrl", e.target.value)
                  }
                  placeholder="Video URL"
                  style={{ width: "100%", marginTop: "8px" }}
                />
              </>
            )}

            {formData.headerType === "document" && (
              <>
                <p style={{ margin: 0, marginTop: 8 }}>Document URL</p>
                <Input
                  value={headerImageUrl}
                  size="large"
                  onChange={(e) =>
                    handleFieldChange("headerImageUrl", e.target.value)
                  }
                  placeholder="Document URL"
                  style={{ width: "100%", marginTop: "8px" }}
                />
              </>
            )}

            {formData.headerType === "location" && (
              <>
                <p style={{ margin: 0, marginTop: 8 }}>Location Url</p>
                <Input
                  value={headerImageUrl} // You can use headerImageUrl for latitude
                  size="large"
                  onChange={(e) =>
                    handleFieldChange("headerImageUrl", e.target.value)
                  }
                  placeholder="Latitude"
                  style={{ width: "100%", marginTop: "8px" }}
                />
              </>
            )}

            {inputFields}

            {variables.length === 0 && formData.headerType === "text" && (
              <p>
                You haven't used any variable placeholders in your text. In
                order to add a variable, go back to the editor and insert at the
                given position, where # represents the variable index, which
                needs to start at 1.
              </p>
            )}

            {variables.length > 0 && formData.headerType !== "text" && (
              <Alert
                style={{ marginTop: 20 }}
                message=""
                description="The provided example values will be submitted to Meta as part of the template submission process. It is required to add example values for all variables in order to submit the template."
                type="warning"
                showIcon
              />
            )}
          </div>
          <div
            style={{
              backgroundColor: "#EBE6DD",
              width: "50%",
              height: "auto",
              padding: "20px",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                alignItems: "center",
                padding: "10px",
                borderRadius: "4px",
              }}
            >
              {formData.headerType === "text" && formData.header && (
                <div style={{ fontWeight: "bold" }}>{formData.header}</div>
              )}
              {formData.headerType === "image" && (
                <>
                  <img
                    src={headerImageUrl}
                    alt="Image Preview"
                    style={{ maxWidth: "100%" }}
                  />
                </>
              )}

              {formData.headerType === "video" && (
                <>
                  <img
                    src={video}
                    alt="Image Preview"
                    style={{ maxWidth: "100%" }}
                  />
                </>
              )}

              {formData.headerType === "document" && (
                <>
                  <img
                    src={doc}
                    alt="Image Preview"
                    style={{ maxWidth: "100%" }}
                  />
                </>
              )}

              {formData.headerType === "location" && (
                <>
                  <img
                    src={location}
                    alt="Image Preview"
                    style={{ maxWidth: "100%" }}
                  />
                </>
              )}
              <p>{renderMessageWithVariables()}</p>
              {formData.footer && <p>{formData.footer}</p>}
            </div>
            <div>
              {formData.buttonType === "Call to Action" && (
                <div
                  style={{
                    backgroundColor: "#fff",
                    marginTop: "4px",
                    borderRadius: "4px",
                  }}
                >
                  {formData.callToActionButtons.map((button, index) => (
                    <div
                      key={index}
                      style={{
                        padding: "5px",
                        textAlign: "center",
                        color: "blue",
                        borderRadius: "4px",
                        border: "1px solid #f9f9f9",
                      }}
                    >
                      <div>{button.label}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div>
              {formData.buttonType === "Quick Reply" && (
                <div
                  style={{
                    backgroundColor: "#fff",
                    marginTop: "4px",
                    borderRadius: "4px",
                  }}
                >
                  {formData.quickButtons.map((button, index) => (
                    <div
                      key={index}
                      style={{
                        padding: "5px",
                        textAlign: "center",
                        color: "blue",
                        borderRadius: "4px",
                        width: "auto",
                        border: "1px solid #f9f9f9",
                      }}
                    >
                      <div>{button.label}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default PreviewPopup;
