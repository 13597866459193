import React, { useState, useContext, useEffect } from "react";
import MainNavigation from "../../shared/menu/MainNavigation";
import { Layout, Table, Spin, Input, Select, Divider } from "antd";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import axios from "axios";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import CountUp from "react-countup";

import { Helmet } from "react-helmet";

import delivered from "../../../img/delivered.png";
import read from "../../../img/read.png";
import sent from "../../../img/sent.png";
import failed from "../../../img/failed.png";

const { Content, Sider } = Layout;
const { Option } = Select;
const { Search } = Input;

function Analytics() {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("Show All");
  const [totalSent, setTotalSent] = useState(0);
  const [totalDelivered, setTotalDelivered] = useState(0);
  const [totalRead, setTotalRead] = useState(0);
  const [totalFailed, setTotalFailed] = useState(0);

  const { isLoading, error, sendRequest } = useHttpClient();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}get-analytics/${auth.userId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        const rawData = response.data;

        // Process the data
        const processedData = rawData.reduce((acc, entry) => {
          const date = new Date(entry.timestamp * 1000)
            .toISOString()
            .split("T")[0];

          const existingEntry = acc.find((item) => item.date === date);

          if (existingEntry) {
            existingEntry[entry.status]++;
          } else {
            const newEntry = {
              date,
              sent: entry.status === "sent" ? 1 : 0,
              delivered: entry.status === "delivered" ? 1 : 0,
              read: entry.status === "read" ? 1 : 0,
              failed: entry.status === "failed" ? 1 : 0,
            };
            acc.push(newEntry);
          }

          return acc;
        }, []);

        // Sort data by date in ascending order
        const sortedData = processedData.sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );

        setData(sortedData);
        setFilteredData(sortedData); // Initialize filteredData with all data
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [auth.token, auth.userId]);

  useEffect(() => {
    // Calculate total counts based on all data
    const totalSentCount = data.reduce((total, entry) => total + entry.sent, 0);
    const totalDeliveredCount = data.reduce(
      (total, entry) => total + entry.delivered,
      0
    );
    const totalReadCount = data.reduce((total, entry) => total + entry.read, 0);
    const totalFailedCount = data.reduce(
      (total, entry) => total + entry.failed,
      0
    );

    setTotalSent(totalSentCount);
    setTotalDelivered(totalDeliveredCount);
    setTotalRead(totalReadCount);
    setTotalFailed(totalFailedCount);
  }, [data]);

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);

    // Apply the selected filter
    if (filter === "Show All") {
      setFilteredData(data);
    } else if (filter === "Yesterday") {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const filtered = data.filter(
        (entry) => entry.date === yesterday.toISOString().split("T")[0]
      );
      setFilteredData(filtered);
    } else if (filter === "Week") {
      const currentDate = new Date();
      const firstDayOfWeek = new Date(
        currentDate.setDate(currentDate.getDate() - currentDate.getDay())
      );
      const filtered = data.filter(
        (entry) => new Date(entry.date) >= firstDayOfWeek
      );
      setFilteredData(filtered);
    } else if (filter === "This Month") {
      const currentDate = new Date();
      const firstDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const filtered = data.filter(
        (entry) => new Date(entry.date) >= firstDayOfMonth
      );
      setFilteredData(filtered);
    } else {
      // Custom Date Range Filter - Handle accordingly
      // You may use a date range picker library for better user experience
    }
  };

  return (
    <Layout hasSider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WhatsApp Campaigns | C-Edge BigRadar</title>
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          {loading ? (
            <Spin />
          ) : (
            <div
              style={{
                padding: 24,
                background: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 20,
                  padding: 10,
                }}
              >
                <div
                  style={{
                    height: "9rem",
                    borderRadius: "6px",
                    border: "1px solid #f4f4f4",
                    padding: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <img style={{ width: "50%" }} src={sent} alt="sent" />
                    </div>
                    <div style={{ width: "50%", textAlign: "right" }}>
                      <h3>Total Sent</h3>
                      <CountUp end={totalSent || 0} duration={totalSent % 10} />
                    </div>
                  </div>
                  <Divider style={{ marginTop: 10 }} />
                  <h5 style={{ margin: 0 }}>updated 1 minute ago</h5>
                </div>
                <div
                  style={{
                    height: "9rem",
                    borderRadius: "6px",
                    border: "1px solid #f4f4f4",
                    padding: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <img
                        style={{ width: "50%" }}
                        src={delivered}
                        alt="sent"
                      />
                    </div>
                    <div style={{ width: "50%", textAlign: "right" }}>
                      <h3>Total Delivered</h3>
                      <CountUp
                        end={totalDelivered || 0}
                        duration={totalDelivered % 10}
                      />
                    </div>
                  </div>
                  <Divider style={{ marginTop: 10 }} />
                  <h5 style={{ margin: 0 }}>updated 1 minute ago</h5>
                </div>
                <div
                  style={{
                    height: "9rem",
                    borderRadius: "6px",
                    border: "1px solid #f4f4f4",
                    padding: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <img style={{ width: "50%" }} src={read} alt="sent" />
                    </div>
                    <div style={{ width: "50%", textAlign: "right" }}>
                      <h3>Total Read</h3>
                      <CountUp end={totalRead || 0} duration={totalRead % 10} />
                    </div>
                  </div>
                  <Divider style={{ marginTop: 10 }} />
                  <h5 style={{ margin: 0 }}>updated 1 minute ago</h5>
                </div>
                <div
                  style={{
                    height: "9rem",
                    borderRadius: "6px",
                    border: "1px solid #f4f4f4",
                    padding: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <img style={{ width: "50%" }} src={failed} alt="sent" />
                    </div>
                    <div style={{ width: "50%", textAlign: "right" }}>
                      <h3>Total Failed</h3>
                      <CountUp
                        end={totalFailed || 0}
                        duration={totalFailed % 10}
                      />
                    </div>
                  </div>
                  <Divider style={{ marginTop: 10 }} />
                  <h5 style={{ margin: 0 }}>updated 1 minute ago</h5>
                </div>
              </div>
              <Select
                value={selectedFilter}
                onChange={(value) => handleFilterChange(value)}
                placeholder="Time Filter"
                defaultValue="Show All"
                style={{ width: "150px" }}
              >
                <Option value="Show All">Show All</Option>
                <Option value="Yesterday">Yesterday</Option>
                <Option value="Week">Week</Option>
                <Option value="This Month">This Month</Option>
              </Select>

              <ResponsiveContainer width="99%" height={450}>
                <AreaChart data={filteredData} margin={{ top: 20 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Area
                    type="monotone"
                    dataKey="sent"
                    stroke="#8884d8"
                    fill="#8884d8"
                  />
                  <Area
                    type="monotone"
                    dataKey="delivered"
                    stroke="#82ca9d"
                    fill="#82ca9d"
                  />
                  <Area
                    type="monotone"
                    dataKey="read"
                    stroke="#ffc658"
                    fill="#ffc658"
                  />
                  <Area
                    type="monotone"
                    dataKey="failed"
                    stroke="#f94144"
                    fill="#f94144"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          )}
        </Content>
      </Layout>
    </Layout>
  );
}

export default Analytics;
