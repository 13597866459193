import React, { useState, useContext, useEffect } from "react";
import MainNavigation from "../../shared/menu/MainNavigation";
import { Link } from "react-router-dom";
import {
  Layout,
  Space,
  Table,
  Result,
  Form,
  Row,
  Col,
  Button,
  Spin,
  Statistic,
} from "antd";
import { SendOutlined, InboxOutlined } from "@ant-design/icons";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { Helmet } from "react-helmet";
import axios from "axios";
const { Content, Sider } = Layout;

function Credits() {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();

  const dataSource = [];

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}profile/${auth.userId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        setProfile(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile();
  }, [auth.token, auth.userId]);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "No. of Credits",
      dataIndex: "credits",
      key: "credits",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <Layout hasSider>
      {loading ? <Spin /> : !loading}
      <Helmet>
        <meta charSet="utf-8" />
        <title>WhatsApp Campaigns | C-Edge BigRadar</title>
        <link
          rel="canonical"
          href="https://app.billsubmit.com/create-company"
        />
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div
              style={{ display: "flex", gap: "10px", alignItems: "flex-end" }}
            >
              <h2 style={{ margin: 0 }}>Current Balance</h2>
              <Link>
                <p style={{ margin: 0 }}>Usage Stats</p>
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                gap: "40px",
                marginTop: "30px",
              }}
            >
              <div style={{ width: "50%" }}>
                <div
                  style={{
                    backgroundColor: "#f9f9f9",
                    padding: "20px",
                    display: "flex",
                    alignItems: "center",
                    gap: "300px",
                  }}
                >
                  <Statistic
                    title="Inbound"
                    value={profile?.inBoundCredits}
                    prefix={<InboxOutlined />}
                  />
                  <Statistic
                    title="Outbound"
                    value={profile?.outBoundCredits}
                    prefix={<SendOutlined />}
                  />
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <h2>How to recharge?</h2>
                <p>
                  Please contact your relationship manager to add balance in
                  your Whatsapp Banking Account. You can also contact
                  team@bigradar.io
                </p>
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h4>Purchase History</h4>

              <Table
                dataSource={dataSource}
                columns={columns}
                rowKey={(record) => record.id}
                size="middle"
                pagination={{ pageSize: 25 }}
              />
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Credits;
