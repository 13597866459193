import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import MainNavigation from "../../shared/menu/MainNavigation";
import {
  Layout,
  Table,
  Spin,
  Input,
  Row,
  Col,
  Statistic,
  message,
  Button,
  DatePicker,
} from "antd";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import axios from "axios";
import { Helmet } from "react-helmet";
import gscBanks from "../../../gsc-sample.csv";
import moment from "moment";

const { Content, Sider } = Layout;
const { Search } = Input;
const { RangePicker } = DatePicker;

function AllContacts() {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}get-contacts/${auth.userId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        setContacts(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile();
  }, [auth.token, auth.userId]);

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const handleDateRangeChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const filteredContacts = contacts?.filter((contact) => {
    const isNameMatch =
      contact?.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
      contact?.phoneNumber.includes(searchQuery);

    const isDateInRange =
      (!startDate ||
        moment(contact.uploadedAt).isSameOrAfter(startDate, "day")) &&
      (!endDate || moment(contact.uploadedAt).isSameOrBefore(endDate, "day"));

    return isNameMatch && isDateInRange;
  });

  const filteredByDateRange = filteredContacts?.filter((contact) => {
    if (dateRange.length === 0) return true;
    const uploadedAt = moment(contact.uploadedAt);
    return uploadedAt.isBetween(dateRange[0], dateRange[1]);
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phone",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      key: "pincode",
    },
    {
      title: "Created At",
      dataIndex: "uploadedAt",
      key: "uploadedAt",
      render: (uploadedAt) =>
        uploadedAt ? moment(uploadedAt).format("YYYY-MM-DD") : "--",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <Link to={`/edit-contact/${record.id}`}>Edit</Link>
          <Button
            type="link"
            style={{ color: "red" }}
            onClick={() => handleDelete(record._id)}
          >
            Delete
          </Button>
        </span>
      ),
    },
  ];

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}delete-contact/${id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      setLoading(false);
      message.success("Contact deleted successfully");
      // Fetch contacts again after deletion
      fetchContacts();
    } catch (error) {
      setLoading(false);
      message.error("Failed to delete contact. Please try again.");
      console.error("Error deleting contact:", error);
    }
  };

  const fetchContacts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}get-contacts/${auth.userId}`,
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      );
      setContacts(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("csvFile", file);

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}import-contacts/${auth.userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLoading(false);
      message.success(response.data.message);
      setContacts(response.data.contacts);
    } catch (error) {
      setLoading(false);
      message.error("Failed to import contacts. Please try again.");
      console.error("Error importing contacts:", error);
    }
  };

  return (
    <Layout hasSider>
      {loading && <Spin />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>WhatsApp Campaigns | C-Edge BigRadar</title>
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <h2 style={{ margin: 0 }}>All Contacts</h2>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                gap: 30,
              }}
            >
              <div style={{ width: "50%" }}>
                <Search
                  placeholder="Search contacts"
                  onSearch={handleSearch}
                  style={{ width: "70%", marginBottom: 16 }}
                />
              </div>
              <div
                style={{ width: "50%", display: "flex", alignItems: "center" }}
              >
                <Statistic title="Total Contacts" value={contacts.length} />
              </div>
            </div>
            {/* <div style={{ marginTop: "20px" }}>
              <DatePicker.RangePicker
                style={{ marginRight: 16 }}
                onChange={handleDateRangeChange}
              />
            </div> */}
            <div style={{ marginLeft: "0px" }}>
              <p>Import Contacts</p>
              <input type="file" onChange={handleFileChange} accept=".csv" />
              <a href={gscBanks} download="demo.csv">
                Download Demo File
              </a>
            </div>

            <div style={{ marginTop: "20px" }}>
              <Table
                dataSource={filteredByDateRange}
                columns={columns}
                rowKey={(record) => record.id}
                size="middle"
                pagination={{ pageSize: 25 }}
              />
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default AllContacts;
