import React, { useState, useContext, useEffect } from "react";
import MainNavigation from "../../shared/menu/MainNavigation";
import {
  Layout,
  Spin,
  Table,
  Button,
  Card,
  Badge,
  Modal,
  Select,
  Divider,
  DatePicker,
  Tag,
  Input,
} from "antd";
import { AuthContext } from "../../shared/context/auth-context";
import { SearchOutlined } from "@ant-design/icons";
import sent from "../../../img/sent.png";
import read from "../../../img/read.png";
import failed from "../../../img/failed.png";
import delivered from "../../../img/delivered.png";
import CountUp from "react-countup";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment";

const { Content, Sider } = Layout;
const { Option } = Select;
const { Search } = Input;

function CampaignAnalytics() {
  const { campaignId } = useParams();
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("Show All");
  const [totalSent, setTotalSent] = useState(0);
  const [totalDelivered, setTotalDelivered] = useState(0);
  const [totalRead, setTotalRead] = useState(0);
  const [totalFailed, setTotalFailed] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");

  useEffect(() => {
    // Fetch message events from the backend API
    const fetchMessageEvents = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}campaign-analytics/${campaignId}/${auth.userId}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        const rawData = response.data.messageEvents;

        console.log("raw Data:", response.data.messageEvents);

        // Process the data
        const processedData = rawData.reduce((acc, entry) => {
          const date = new Date(entry.timestamp * 1000)
            .toISOString()
            .split("T")[0];

          const existingEntry = acc.find((item) => item.date === date);

          if (existingEntry) {
            existingEntry[entry.status]++;
          } else {
            const newEntry = {
              date,
              sent: entry.status === "sent" ? 1 : 0,
              delivered: entry.status === "delivered" ? 1 : 0,
              read: entry.status === "read" ? 1 : 0,
              failed: entry.status === "failed" ? 1 : 0,
            };
            acc.push(newEntry);
          }

          return acc;
        }, []);

        const sortedData = processedData.sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );

        console.log("Sorted Data:", sortedData);

        setData(sortedData);
        setTableData(response.data.messageEvents);
        setFilteredData(sortedData);
      } catch (error) {
        console.error("Error fetching message events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMessageEvents();
  }, [auth.userId, campaignId, auth.token]);

  // useEffect(() => {
  //   const fetchAnalyticsData = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BACKEND_URL}campaign-analytics/${campaignId}/${auth.userId}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${auth.token}`,
  //           },
  //         }
  //       );

  //       console.log(response.data);
  //       setAnalyticsData(response.data.campaign);
  //     } catch (error) {
  //       console.error("Error fetching campaign analytics data:", error);
  //     }
  //     setLoading(false);
  //   };

  //   fetchAnalyticsData();
  // }, [auth.userId, campaignId, auth.token]);

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        let color;
        switch (text) {
          case "sent":
            color = "#8884d8";
            break;
          case "delivered":
            color = "#82ca9d";
            break;
          case "read":
            color = "#ffc658";
            break;
          case "failed":
            color = "#f94144";
            break;
          default:
            color = "";
        }
        return (
          <div>
            <Tag color={color}>{text}</Tag>
          </div>
        );
      },
      filters: [
        { text: "Sent", value: "sent" },
        { text: "Delivered", value: "delivered" },
        { text: "Read", value: "read" },
        { text: "Failed", value: "failed" },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Recipient ID",
      dataIndex: "recipient_id",
      key: "recipient_id",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Search
            placeholder="Search Recipient ID"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onSearch={confirm}
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={confirm}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#1890ff" : undefined }}
          rev={undefined}
        />
      ),
      onFilter: (value, record) =>
        record.recipient_id?.toLowerCase().includes(value.toLowerCase()),
      // ... (other properties)
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text) => moment(text * 1000).format("YYYY-MM-DD HH:mm:ss"),
    },
  ];

  const handleSearch = (value) => {
    const filteredTableData = tableData.filter((record) =>
      record.recipient_id?.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredTableData);
  };

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
    setStatusFilter(value);
    if (value === "Show All") {
      setFilteredData(tableData);
    } else {
      const filteredTableData = tableData.filter(
        (record) => record.status === value
      );
      setFilteredData(filteredTableData);
    }
  };

  useEffect(() => {
    // Calculate total counts based on all data
    const totalSentCount = data.reduce((total, entry) => total + entry.sent, 0);
    const totalDeliveredCount = data.reduce(
      (total, entry) => total + entry.delivered,
      0
    );
    const totalReadCount = data.reduce((total, entry) => total + entry.read, 0);
    const totalFailedCount = data.reduce(
      (total, entry) => total + entry.failed,
      0
    );

    setTotalSent(totalSentCount);
    setTotalDelivered(totalDeliveredCount);
    setTotalRead(totalReadCount);
    setTotalFailed(totalFailedCount);
  }, [data]);

  console.log(analyticsData, "ss");

  return (
    <Layout hasSider>
      {loading ? <Spin /> : null}
      <Helmet>
        <meta charSet="utf-8" />
        <title>WhatsApp Campaigns | C-Edge BigRadar</title>
        <link
          rel="canonical"
          href="https://app.billsubmit.com/create-company"
        />
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: 200 }}>
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div className="main-heading">
              <div>
                <h2>WhatsApp Campaign Analytics</h2>
              </div>
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 20,
                  padding: 10,
                }}
              >
                <div
                  style={{
                    height: "9rem",
                    borderRadius: "6px",
                    border: "1px solid #f4f4f4",
                    padding: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <img style={{ width: "50%" }} src={sent} alt="sent" />
                    </div>
                    <div style={{ width: "50%", textAlign: "right" }}>
                      <h3>Total Sent</h3>
                      <CountUp end={totalSent || 0} duration={totalSent % 10} />
                    </div>
                  </div>
                  <Divider style={{ marginTop: 10 }} />
                  <h5 style={{ margin: 0 }}>updated 1 minute ago</h5>
                </div>
                <div
                  style={{
                    height: "9rem",
                    borderRadius: "6px",
                    border: "1px solid #f4f4f4",
                    padding: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <img
                        style={{ width: "50%" }}
                        src={delivered}
                        alt="sent"
                      />
                    </div>
                    <div style={{ width: "50%", textAlign: "right" }}>
                      <h3>Total Delivered</h3>
                      <CountUp
                        end={totalDelivered || 0}
                        duration={totalDelivered % 10}
                      />
                    </div>
                  </div>
                  <Divider style={{ marginTop: 10 }} />
                  <h5 style={{ margin: 0 }}>updated 1 minute ago</h5>
                </div>
                <div
                  style={{
                    height: "9rem",
                    borderRadius: "6px",
                    border: "1px solid #f4f4f4",
                    padding: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <img style={{ width: "50%" }} src={read} alt="sent" />
                    </div>
                    <div style={{ width: "50%", textAlign: "right" }}>
                      <h3>Total Read</h3>
                      <CountUp end={totalRead || 0} duration={totalRead % 10} />
                    </div>
                  </div>
                  <Divider style={{ marginTop: 10 }} />
                  <h5 style={{ margin: 0 }}>updated 1 minute ago</h5>
                </div>
                <div
                  style={{
                    height: "9rem",
                    borderRadius: "6px",
                    border: "1px solid #f4f4f4",
                    padding: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <img style={{ width: "50%" }} src={failed} alt="sent" />
                    </div>
                    <div style={{ width: "50%", textAlign: "right" }}>
                      <h3>Total Failed</h3>
                      <CountUp
                        end={totalFailed || 0}
                        duration={totalFailed % 10}
                      />
                    </div>
                  </div>
                  <Divider style={{ marginTop: 10 }} />
                  <h5 style={{ margin: 0 }}>updated 1 minute ago</h5>
                </div>
              </div>

              <div style={{ padding: 20 }}>
                <Table
                  columns={columns}
                  dataSource={tableData}
                  loading={loading}
                  rowKey="_id"
                  pagination={{
                    pageSize: 100,
                  }}
                />
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default CampaignAnalytics;
