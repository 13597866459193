import React, { useEffect, useState, useContext } from "react";
import MainNavigation from "../../shared/menu/MainNavigation";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { ConnectButton } from "360dialog-connect-button";
import axios from "axios";

import moment from "moment";

import { Layout, Space, Table, Tag, Button, Select } from "antd";
const { Content, Sider } = Layout;
const { Option } = Select;

// Ensure that 'FB' is defined
const FB = window.FB;

function Dashboard() {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [loadedBills, setLoadedBills] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [bills, setBills] = useState([]);
  const [filteredBills, setFilteredBills] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [currentBill, setCurrentBill] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  function statusChangeCallback(response) {
    if (response.status === "connected") {
      // User is logged in
      const accessToken = response.authResponse.accessToken;
      // You can use the accessToken or perform other actions here
    } else {
      // User is not logged in or has not authorized the app
      console.log("User is not logged in or has not authorized the app.");
    }
  }

  function checkLoginState(response) {
    statusChangeCallback(response);
  }

  function handleFacebookLogin() {
    FB.login(
      function (response) {
        if (response.authResponse) {
          // User logged in successfully
          // You can handle the user's login status here
          checkLoginState(response);
        } else {
          // User canceled the login or didn't authorize the app
          console.log("Facebook login cancelled or not authorized.");
        }
      },
      {
        config_id: "274026702181017", // right
        // configId: '<CONFIG_ID>' // wrong
      },
      { scope: "email" }
    ); // Specify the scope you need
  }

  useEffect(() => {
    const fetchBills = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/accounts/${auth.userId}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        const bills = response.data.map((row) => ({
          id: row._id,
          name: row.name,
          wanumber: row.wanumber,
          email: row.email,
          companyName: row.companyName,
          status: row.status,
          date: moment(row.date).format("YYYY-MM-DD"),
        }));

        // Sort the bills by date in descending order
        bills.sort((a, b) => new Date(b.date) - new Date(a.date));

        setBills(bills);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchBills();
  }, [auth.userId, auth.token]);

  // https://hub.360dialog.com/dashboard/app/<partner-id>/permissions

  const handleCallBack = async (callbackObject) => {
    console.log("client Id: " + callbackObject.client);
    console.log("channel Ids: " + callbackObject.channels);
    const stateId = new URLSearchParams(window.location.search).get("state");
    if (callbackObject.revokedChannels) {
      console.log("revoked Channel Id: " + callbackObject.revokedChannels);
    }
    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "update-account",
        {
          clientId: callbackObject.client,
          channelId: callbackObject.channels,
          stateId: stateId,
          creator: auth.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
        }
      );
      console.log("Data saved successfully");
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      title: "Account Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link
          to={`/bills/details/${record.id}`}
          style={{ textDecoration: "none" }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "WhatsApp Number",
      dataIndex: "wanumber",
      key: "wanumber",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text) => (
        <Tag color={text === "Connected" ? "green" : "red"}>{text}</Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <ConnectButton
            partnerId={"46C3maPA"}
            callback={handleCallBack}
            label="Create WABA Account"
            style={{
              color: "black",
              backgroundColor: "white",
              border: "1px solid #000",
              padding: "8px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            queryParameters={{
              email: record.email,
              name: record.name,
              state: record.id,
              partner: record.id,
              redirect_url: "https://wa-bigradar.web.app/",
            }}
          /> */}
          {/* <Button type="primary">Connect With Facebook</Button> */}
          <Button type="primary" onClick={handleFacebookLogin}>
            Connect With Facebook
          </Button>
        </Space>
      ),
    },
  ];

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div className="">
              <div style={{ display: "flex", marginTop: "20px" }}>
                <div style={{ flex: 1, marginRight: "20px" }}>
                  <div className="main-heading">
                    <div>
                      <h2>WA Accounts</h2>
                    </div>
                    {/* <div>
                      <Link to="/create-waaccount">
                        <Button
                          type="primary"
                          size="large"
                          style={{ borderRadius: "4px", marginLeft: "20px" }}
                        >
                          Create WA Account
                        </Button>
                      </Link>
                    </div> */}
                  </div>
                  <Table
                    style={{ marginTop: "0px" }}
                    dataSource={bills}
                    columns={columns}
                    rowKey={(record) => record.id}
                    size="middle"
                    pagination={{ pageSize: 25 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
