import React, { useState, useContext, useEffect } from "react";
import MainNavigation from "../shared/menu/MainNavigation";
import { Link } from "react-router-dom";
import {
  Layout,
  Space,
  Table,
  Result,
  Form,
  Row,
  Col,
  Button,
  Modal,
  Spin,
  Tooltip,
  Tag,
} from "antd";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from "../shared/context/auth-context";
import { Helmet } from "react-helmet";
import moment from "moment";
import axios from "axios";
const { Content, Sider } = Layout;

function Templates() {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [templateToDeleteId, setTemplateToDeleteId] = useState(null);

  const [ShowSuccessMessage, setShowSuccessMessage] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();

  const showDeleteConfirmation = (templateId) => {
    setTemplateToDeleteId(templateId);
    setDeleteModalVisible(true);
  };

  useEffect(() => {
    const fetchBills = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}get-templates/${auth.userId}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );

        console.log("response Data", response.data);
        const templates = response.data.waba_templates.map((row) => ({
          id: row.id,
          name: row.name,
          category: row.category,
          preview: row.components[1]?.text,
          status: row.status,
          language: row.language,
        }));

        setLoading(false);
        console.log(templates, "templates");
        setTemplates(templates);
      } catch (err) {
        console.log(err);
      }
    };
    fetchBills();
  }, [auth.userId, auth.token]);

  const columns = [
    {
      title: "Template Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Preview",
      dataIndex: "preview",
      key: "preview",
      render: (text) => (
        <Tooltip title={text}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "200px", // Adjust the maximum width as needed
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        // Map status values to corresponding tags
        const statusTags = {
          draft: <Tag color="default">Draft</Tag>,
          approved: <Tag color="success">Approved</Tag>,
          pending: <Tag color="processing">Pending</Tag>,
          rejected: <Tag color="error">Rejected</Tag>,
          paused: <Tag color="warning">Paused</Tag>,
        };
        return statusTags[status] || status;
      },
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {/* <Button type="primary" onClick={() => handleEdit(record)}> */}
          <Link to={`/edit-template/${record.id}`}>
            <Button type="primary" disabled>
              Edit
            </Button>
          </Link>
          <Button
            type="danger"
            onClick={() => showDeleteConfirmation(record.id)}
            style={{ color: "red" }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const handleDeleteTemplate = async () => {
    try {
      setLoading(true);
      // Send a DELETE request to the API to delete the template using templateToDeleteId
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL +
          `delete-template/${templateToDeleteId}`,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
          },
        }
      );

      // Update the templates state by removing the deleted template
      setTemplates((prevTemplates) =>
        prevTemplates.filter((template) => template.id !== templateToDeleteId)
      );
      setLoading(false);

      // Close the delete confirmation modal
      setDeleteModalVisible(false);
    } catch (error) {
      console.error("Error deleting template:", error);
    }
  };

  return (
    <Layout hasSider>
      {loading ? <Spin /> : !loading}
      <Helmet>
        <meta charSet="utf-8" />
        <title>WhatsApp Campaigns | C-Edge BigRadar</title>
        <link
          rel="canonical"
          href="https://app.billsubmit.com/create-company"
        />
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <Modal
              title="Confirm Delete"
              visible={deleteModalVisible}
              onOk={handleDeleteTemplate}
              onCancel={() => setDeleteModalVisible(false)}
            >
              <p>Are you sure you want to delete this template?</p>
            </Modal>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div>
                <h2>All Templates</h2>
              </div>
              <div>
                <Link to="/create-template">
                  <Button
                    type="primary"
                    size="large"
                    style={{ borderRadius: "4px", marginLeft: "20px" }}
                    // disabled
                  >
                    Create New Template
                  </Button>
                </Link>
              </div>
            </div>

            <Table
              dataSource={templates}
              columns={columns}
              loading={loading}
              rowKey={(record) => record.id}
              size="middle"
              pagination={{ pageSize: 25 }}
            />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Templates;
