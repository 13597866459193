import React, { useState, useEffect, useContext } from "react";
import SideMenu from "./SideMenu";
import { AuthContext } from "../context/auth-context";
import "./MainNavigation.css";
import { NavLink } from "react-router-dom";
import { useHttpClient } from "../hooks/http-hook";
import logo from "../../../img/logo-white-bill.png";
import diamond from "../../../img/Annual.png";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import axios from "axios";

const MainNavigation = (props) => {
  const auth = useContext(AuthContext);
  const [profile, setProfile] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [subscription, setSubscription] = useState(null);

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <div style={{ padding: "1.9rem" }}>
        <img style={{ width: "100%" }} src={logo} alt="logo" />
        {/* <h1 style={{ color: "white", fontSize: "1.2rem", margin: "0" }}>
          BillSubmit
        </h1> */}
      </div>
      <ul className="nav-links">
        <li>
          <NavLink to="/" exact={true}>
            WA Accounts
          </NavLink>
        </li>
        <li>
          <NavLink to="/campaigns" exact={true}>
            WA Campaigns
          </NavLink>
        </li>
        <li>
          <NavLink to="/templates" exact={true}>
            Templates
          </NavLink>
        </li>
        <li>
          <NavLink to="/contacts" exact={true}>
            Contacts
          </NavLink>
        </li>
        <li>
          <NavLink to="/analytics" exact={true}>
            Analytics
          </NavLink>
        </li>
        <li>
          <NavLink to="/credits" exact={true}>
            Credits
          </NavLink>
        </li>
        <li>
          <NavLink to="/logout" onClick={auth.logout} exact={true}>
            Logout
          </NavLink>
        </li>
        <li>
          <NavLink to="/profile" exact={true}>
            Profile
          </NavLink>
        </li>
      </ul>
      <div>
        {/* <div>
          {subscription && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                margin: "1.2rem",
                padding: "10px",
              }}
            >
              <img style={{ width: "2.2rem" }} src={diamond} alt="diamond" />
              <div>
                <h3 style={{ color: "white", margin: 0 }}>
                  {subscription ? "Yearly Plan" : "Not Subscribed"}
                </h3>
                <p style={{ color: "white", margin: 0, marginTop: "4px" }}>
                  {subscription ? subscription.status : "Not Subscribed"}
                </p>
              </div>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default MainNavigation;
