import React, { useState, useEffect, useContext } from "react";
import { Layout, Button, Form, Input, Spin, Result, Select, Alert } from "antd";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import MainNavigation from "../../shared/menu/MainNavigation";
import gscBanks from "../../../gsc-sample.csv";

const { Content, Sider } = Layout;
const { Search } = Input;

function CreateCampaign() {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [number, setNumber] = useState();
  const [email, setEmail] = useState();
  const [messageSent, setMessageSent] = useState();
  const [templates, setTemplates] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [ShowSuccessMessage, setShowSuccessMessage] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [variableSelection, setVariableSelection] = useState({});
  const [profile, setProfile] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setCsvFile(file);

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const rows = text.split("\n");
      const headers = rows[0].split(","); // Assuming CSV headers are in the first row
      setCsvHeaders(headers);

      // Parse CSV data
      const data = rows.slice(1).map((row) => {
        const values = row.split(",");
        // Assuming each row has the same number of values as headers
        return headers.reduce((obj, header, index) => {
          obj[header] = values[index];
          return obj;
        }, {});
      });
      setCsvData(data);
    };
    reader.readAsText(file);
  };

  const handleChangeTemplate = (value) => {
    const selected = templates.find((template) => template.name === value);
    setVariableSelection({});
    setImageUrl("");
    setVideoUrl("");

    setSelectedTemplate(selected);
  };

  useEffect(() => {
    const fetchBills = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}get-templates/${auth.userId}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );

        console.log("response Data", response.data);
        const templates = response.data.waba_templates;

        setLoading(false);
        console.log(templates, "templates");
        setTemplates(templates);
      } catch (err) {
        console.log(err);
      }
    };

    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}profile/${auth.userId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        setProfile(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile();
    fetchBills();
  }, [auth.userId, auth.token]);

  // Function to find dynamic tags in the template components
  const findDynamicTags = () => {
    const dynamicTags = [];
    selectedTemplate?.components?.forEach((component) => {
      if (component.text && typeof component.text === "string") {
        const matches = component.text.match(/{{\d+}}/g);
        if (matches) {
          matches.forEach((match) => {
            const tag = match.slice(2, -2);
            dynamicTags.push(tag);
          });
        }
      }
    });
    return [...new Set(dynamicTags)]; // Remove duplicates
  };

  // Handle change for dynamic tag data selection
  const handleDynamicTagChange = (tag, value) => {
    setVariableSelection((prevSelection) => ({
      ...prevSelection,
      [tag]: value,
    }));
  };

  console.log(videoUrl, "Video");

  const handleFormSubmit = async () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("language", selectedTemplate.language);
    formData.append("templateName", selectedTemplate.name);
    formData.append("namespace", selectedTemplate.namespace);
    formData.append("variableSelection", JSON.stringify(variableSelection));
    formData.append("csvFile", csvFile);
    formData.append("headerImageUrl", imageUrl);
    formData.append("headerVideoUrl", videoUrl);

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}send-whatsapp-campaign/${auth.userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response as needed
      console.log("Campaign sent successfully!", response.data);
      setMessageSent(response.data.messagesSent);
      setLoading(false);
      setShowSuccessMessage(true);
    } catch (error) {
      // Handle errors from the API request
      console.error("Error sending campaign:", error);
      setLoading(false);
      setShowSuccessMessage(true);
    }
  };

  console.log(variableSelection, "veriablle select");
  console.log(selectedTemplate, "selected select");

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            {ShowSuccessMessage ? (
              <Result
                status="success"
                title={`Successfully sent the campaign to ${messageSent} contacts`}
                extra={[
                  <Button type="primary" key="success">
                    <Link to="/campaigns">Go Back</Link>
                  </Button>,
                ]}
              />
            ) : (
              <>
                {loading ? (
                  <Spin />
                ) : (
                  <div className="">
                    <div style={{ display: "flex", marginTop: "20px" }}>
                      <div style={{ flex: 1, marginRight: "20px" }}>
                        <div className="main-heading">
                          <div>
                            <h2>Create Campaign</h2>
                          </div>
                        </div>
                        {loading ? <Spin /> : !loading}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            gap: "20px",
                          }}
                        >
                          <div style={{ width: "50%" }}>
                            <Form layout="vertical">
                              <Form.Item
                                label="Campaign Name"
                                style={{ width: "100%" }}
                              >
                                <Input
                                  id="name"
                                  placeholder="Example: Name_Date"
                                  type="text"
                                  size="large"
                                  onChange={(e) => setName(e.target.value)}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your full name!",
                                    },
                                  ]}
                                />
                              </Form.Item>
                              <Form.Item
                                label="Select Template"
                                style={{ width: "100%" }}
                              >
                                <Select
                                  placeholder="Select Template"
                                  style={{ width: "100%" }}
                                  size="large"
                                  onChange={handleChangeTemplate}
                                  options={templates?.map((template) => ({
                                    value: template?.name, // You can adjust this based on how you want to identify the template
                                    label: template?.preview || template?.name, // Display preview text or name as the label
                                  }))}
                                />
                              </Form.Item>
                              <Form.Item
                                label="Upload Contact CSV File"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="file"
                                  onChange={handleFileUpload}
                                  accept=".csv"
                                />
                                <div style={{ marginTop: "20px" }}>
                                  <a
                                    href={gscBanks} // Specify the path to your demo CSV file
                                    download="demo.csv" // Specify the filename for the downloaded file
                                  >
                                    Download Demo File
                                  </a>
                                </div>
                              </Form.Item>

                              {selectedTemplate &&
                                selectedTemplate.components &&
                                selectedTemplate.components.some(
                                  (component) =>
                                    component.type === "HEADER" &&
                                    (component.format === "IMAGE" ||
                                      component.format === "VIDEO")
                                ) && (
                                  <>
                                    <Form.Item
                                      label="Header Image URL"
                                      style={{ width: "100%" }}
                                    >
                                      <Input
                                        placeholder="Enter Header Image URL"
                                        value={imageUrl}
                                        onChange={(e) =>
                                          setImageUrl(e.target.value)
                                        }
                                        disabled={
                                          !selectedTemplate ||
                                          !selectedTemplate.components.some(
                                            (component) =>
                                              component.type === "HEADER" &&
                                              component.format === "IMAGE"
                                          )
                                        }
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      label="Header Video URL"
                                      style={{ width: "100%" }}
                                    >
                                      <Input
                                        placeholder="Enter Header Video URL"
                                        value={videoUrl}
                                        onChange={(e) =>
                                          setVideoUrl(e.target.value)
                                        }
                                        disabled={
                                          !selectedTemplate ||
                                          !selectedTemplate.components.some(
                                            (component) =>
                                              component.type === "HEADER" &&
                                              component.format === "VIDEO"
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </>
                                )}

                              {selectedTemplate &&
                                selectedTemplate.components && (
                                  <div>
                                    <h1 style={{ margin: 0 }}>
                                      Dynamic Tag Selection
                                    </h1>
                                    <p
                                      style={{
                                        margin: 0,
                                        marginTop: "2px",
                                        marginBottom: "20px",
                                      }}
                                    >
                                      Please Select a Contact CSV File to see
                                      the options
                                    </p>
                                    {findDynamicTags().map((tag) => (
                                      <>
                                        <Form.Item
                                          key={tag}
                                          label={`Select data for {{${tag}}}`}
                                          style={{ width: "100%" }}
                                        >
                                          <Select
                                            placeholder={`Select data for {{${tag}}}`}
                                            style={{ width: "100%" }}
                                            size="large"
                                            onChange={(value) =>
                                              handleDynamicTagChange(tag, value)
                                            }
                                          >
                                            {/* Options from CSV headers */}
                                            {csvHeaders.map((header) => (
                                              <Select.Option
                                                key={header}
                                                value={header}
                                              >
                                                {header}
                                              </Select.Option>
                                            ))}
                                          </Select>
                                        </Form.Item>
                                      </>
                                    ))}
                                  </div>
                                )}
                              <div style={{ marginBottom: 20 }}>
                                {profile.outBoundCredits === 0 ? (
                                  <Alert
                                    message="Whatsapp Outbound Credits exhausted. Please contact your C-Edge Account Manager to recharge."
                                    type="error"
                                  />
                                ) : null}
                              </div>
                              {profile.outBoundCredits !== 0 ? (
                                <Button
                                  size="large"
                                  type="primary"
                                  htmlType="submit"
                                  onClick={handleFormSubmit}
                                >
                                  Send Now
                                </Button>
                              ) : null}
                            </Form>
                          </div>
                          <div
                            style={{
                              backgroundColor: "#f0f0f0",
                              padding: 20,
                              width: "50%",
                            }}
                          >
                            <h1>Template Information</h1>
                            {selectedTemplate && (
                              <div>
                                <p>Name: {selectedTemplate.name}</p>
                                <p>Category: {selectedTemplate.category}</p>
                                <div style={{ marginBottom: 20 }}>
                                  {selectedTemplate.components?.map(
                                    (component) => (
                                      <>
                                        {component.type === "HEADER" && (
                                          <div key={component.text}>
                                            {component.format === "IMAGE" && (
                                              <img
                                                src={
                                                  component.example
                                                    .header_handle[0]
                                                }
                                                alt="Header Image"
                                                style={{ maxWidth: "100%" }}
                                              />
                                            )}
                                            {component.format === "VIDEO" && (
                                              <video
                                                controls
                                                style={{ maxWidth: "100%" }}
                                              >
                                                <source
                                                  src={
                                                    component.example
                                                      .header_handle[0]
                                                  }
                                                  type="video/mp4"
                                                />
                                                Your browser does not support
                                                the video tag.
                                              </video>
                                            )}
                                          </div>
                                        )}
                                      </>
                                    )
                                  )}
                                </div>
                                <div>
                                  {selectedTemplate.components?.map(
                                    (component) => (
                                      <>
                                        {component.type === "BODY" && (
                                          <div key={component.text}>
                                            <p>{component.text}</p>
                                          </div>
                                        )}
                                      </>
                                    )
                                  )}
                                </div>
                                <div>
                                  {selectedTemplate.components?.map(
                                    (component) => (
                                      <>
                                        {component.type === "FOOTER" && (
                                          <div key={component.text}>
                                            <p>{component.text}</p>
                                          </div>
                                        )}
                                      </>
                                    )
                                  )}
                                </div>
                                <div>
                                  {selectedTemplate.components?.map(
                                    (component) => (
                                      <>
                                        {component.type === "BUTTONS" && (
                                          <div key={component.text}>
                                            <h3>Buttons</h3>
                                            {component.buttons.map(
                                              (button, index) => (
                                                <p key={index}>{button.text}</p>
                                              )
                                            )}
                                          </div>
                                        )}
                                      </>
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default CreateCampaign;
