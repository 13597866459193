import React, { useState, useContext } from "react";
import { Layout, Button, Form, Input, Spin, Result, notification } from "antd";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from "../shared/context/auth-context";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import MainNavigation from "../shared/menu/MainNavigation";

const { Content, Sider } = Layout;

function WaAccount() {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [ShowSuccessMessage, setShowSuccessMessage] = useState();
  const [showResult, setShowResult] = useState(false); // State to control the visibility of the result component
  const { isLoading, error, sendRequest } = useHttpClient();

  const [formData, setFormData] = useState({
    displayName: "",
    email: "",
    password: "",
    contactNumber: "",
    companyName: "",
    companySize: "",
  });

  const handleSubmit = async (e) => {
    try {
      setLoading(true);

      // Add auth.userId to formData
      const formDataWithUserId = {
        ...formData,
        userId: auth.userId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}whatsapp/settings/create-account`,
        formDataWithUserId,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      // Show the result component
      setShowResult(true);
      setLoading(false);
      notification.open({ message: "Profile Created!" });
      console.log(response.data, "Profile Created!");
      history.push("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div style={{ paddingBottom: 20 }}>
              <h1>Create Business Profile</h1>
              <p>
                Business profile will help you create projects to connect with
                the WhatsApp
              </p>
            </div>

            {showResult ? (
              <Result
                status="success"
                title="Profile Created!"
                subTitle="Your business profile has been successfully created."
              />
            ) : (
              <Form layout="vertical" onFinish={handleSubmit}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Form.Item
                    label="Display Name"
                    name="displayName"
                    rules={[{ required: true }]}
                    style={{ width: "100%" }}
                  >
                    <Input
                      size="large"
                      placeholder="Enter your display name"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          displayName: e.target.value,
                        })
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true }]}
                    style={{ width: "100%" }}
                  >
                    <Input
                      size="large"
                      placeholder="Enter your email address"
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                  </Form.Item>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true }]}
                    style={{ width: "100%" }}
                  >
                    <Input.Password
                      size="large"
                      placeholder="Enter your password"
                      onChange={(e) =>
                        setFormData({ ...formData, password: e.target.value })
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label="Contact Number"
                    name="contactNumber"
                    rules={[{ required: true }]}
                    style={{ width: "100%" }}
                  >
                    <Input
                      size="large"
                      placeholder="Enter your full name"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          contactNumber: e.target.value,
                        })
                      }
                    />
                  </Form.Item>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <Form.Item
                    label="Company Name"
                    name="companyName"
                    rules={[{ required: true }]}
                    style={{ width: "100%" }}
                  >
                    <Input
                      size="large"
                      placeholder="Enter your company name"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          companyName: e.target.value,
                        })
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label="Company Size"
                    name="companySize"
                    rules={[{ required: true }]}
                    style={{ width: "100%" }}
                  >
                    <Input
                      size="large"
                      placeholder="Enter your company size"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          companySize: e.target.value,
                        })
                      }
                    />
                  </Form.Item>
                </div>

                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    Create Business Profile
                  </Button>
                </Form.Item>
              </Form>
            )}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default WaAccount;
