import React, { useState, useContext } from "react";
import MainNavigation from "../shared/menu/MainNavigation";
import { Link } from "react-router-dom";
import noImage from "../../img/image.png";
import document from "../../img/doc.png";
import video from "../../img/video.png";
import location from "../../img/location.png";
import {
  Layout,
  Space,
  Table,
  Result,
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
  Spin,
  Checkbox,
} from "antd";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from "../shared/context/auth-context";
import PreviewPopup from "./PreviewPopup";
import { Helmet } from "react-helmet";
import axios from "axios";
const { Content, Sider } = Layout;
const { Option } = Select;
const { TextArea } = Input;

function CreateTemplate() {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [ShowSuccessMessage, setShowSuccessMessage] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [quickButtons, setQuickButtons] = useState([]);
  const [callToActionButtons, setCallToActionButtons] = useState([]);
  const [showPreview, setShowPreview] = useState(false);

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  const [formData, setFormData] = useState({
    templateName: "",
    category: "",
    allowCategoryChange: "",
    templateType: "",
    language: "",
    messageBody: "",
    headerType: "",
    header: "",
    footer: "",
    buttonType: "",
    callToActionType: "",
    buttonLabel: "",
    buttonValue: "",
  });

  const addQuickButton = () => {
    if (quickButtons.length < 3) {
      setQuickButtons([...quickButtons, { label: "", value: "" }]);
    }
  };

  const addCallToActionButton = () => {
    if (callToActionButtons.length < 2) {
      setCallToActionButtons([
        ...callToActionButtons,
        { label: "", value: "", type: "Url" },
      ]);
    }
  };

  const removeQuickButton = (index) => {
    const updatedButtons = [...quickButtons];
    updatedButtons.splice(index, 1);
    setQuickButtons(updatedButtons);

    // Remove the corresponding quick button from the formData object
    setFormData((prevData) => ({
      ...prevData,
      quickButtons: updatedButtons,
    }));
  };

  const removeCallToActionButton = (index) => {
    const updatedButtons = [...callToActionButtons];
    updatedButtons.splice(index, 1);
    setCallToActionButtons(updatedButtons);

    // Remove the corresponding call to action button from the formData object
    setFormData((prevData) => ({
      ...prevData,
      callToActionButtons: updatedButtons,
    }));
  };

  const handleFieldChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleQuickButtonChange = (index, fieldName, value) => {
    const updatedButtons = [...quickButtons];
    updatedButtons[index][fieldName] = value;
    setQuickButtons(updatedButtons);

    // Update the formData object with the updated quickButtons array
    setFormData((prevData) => ({
      ...prevData,
      quickButtons: updatedButtons,
    }));
  };

  const handleCallToActionButtonChange = (index, fieldName, value) => {
    const updatedButtons = [...callToActionButtons];
    updatedButtons[index][fieldName] = value;
    setCallToActionButtons(updatedButtons);

    // Update the formData object with the updated callToActionButtons array
    setFormData((prevData) => ({
      ...prevData,
      callToActionButtons: updatedButtons,
    }));
  };

  // Function to handle form submission
  const handleFormSubmit = () => {
    // Show the preview popup
    togglePreview();
    // Handle the form submission logic here
  };

  console.log(formData, ":Form Data");
  return (
    <Layout hasSider>
      {loading ? <Spin /> : !loading}
      <Helmet>
        <meta charSet="utf-8" />
        <title>WhatsApp Campaigns | C-Edge BigRadar</title>
        <link
          rel="canonical"
          href="https://app.billsubmit.com/create-company"
        />
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div>
              <div>
                <h2>Create New Template</h2>
              </div>
              <Form layout="vertical" onFinish={handleFormSubmit}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <Form.Item
                    label="Template Name"
                    name="templateName"
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter a template name!",
                      },
                      {
                        pattern: /^[a-z]+$/,
                        message:
                          "Template name must be in lowercase without spaces!",
                      },
                    ]}
                  >
                    <Input
                      value={formData.templateName}
                      size="large"
                      onChange={(e) =>
                        handleFieldChange("templateName", e.target.value)
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Category" style={{ width: "100%" }}>
                    <Select
                      value={formData.category}
                      size="large"
                      placeholder="Select Category"
                      onChange={(value) => handleFieldChange("category", value)}
                    >
                      <Option value="MARKETING">Marketing</Option>
                      <Option value="UTILITY">Utility</Option>
                      <Option value="AUTHENTICATION">Authentication</Option>
                    </Select>
                  </Form.Item>
                </div>

                <div
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <Form.Item
                    label="Allow Category Change"
                    style={{ width: "100%" }}
                    tooltip={`Enabling category change allows Meta to assign whatever category they determine to be appropriate. We recommend having this enabled to avoid rejected templates due to miscategorization.\nPlease note that templates can still get rejected for other reasons unrelated to their categorization.`}
                  >
                    <Select
                      value={formData.allowCategoryChange}
                      placeholder="Allow Category Change"
                      size="large"
                      onChange={(value) =>
                        handleFieldChange("allowCategoryChange", value)
                      }
                    >
                      <Option value="Yes">Yes</Option>
                      <Option value="No">No</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item label="Language" style={{ width: "100%" }}>
                    {/* Replace the following Select component with a searchable language select */}
                    <Select
                      size="large"
                      showSearch
                      value={formData.language}
                      onChange={(value) => handleFieldChange("language", value)}
                    >
                      <Option value="af">Afrikaans</Option>
                      <Option value="sq">Albanian</Option>
                      <Option value="ar">Arabic</Option>
                      <Option value="az">Azerbaijani</Option>
                      <Option value="bn">Bengali</Option>
                      <Option value="bg">Bulgarian</Option>
                      <Option value="ca">Catalan</Option>
                      <Option value="zh_CN">Chinese (CHN)</Option>
                      <Option value="zh_HK">Chinese (HKG)</Option>
                      <Option value="zh_TW">Chinese (TAI)</Option>
                      <Option value="hr">Croatian</Option>
                      <Option value="cs">Czech</Option>
                      <Option value="da">Danish</Option>
                      <Option value="nl">Dutch</Option>
                      <Option value="en">English</Option>
                      <Option value="en_GB">English (UK)</Option>
                      <Option value="en_US">English (US)</Option>
                      <Option value="et">Estonian</Option>
                      <Option value="fil">Filipino</Option>
                      <Option value="fi">Finnish</Option>
                      <Option value="fr">French</Option>
                      <Option value="ka">Georgian</Option>
                      <Option value="de">German</Option>
                      <Option value="el">Greek</Option>
                      <Option value="gu">Gujarati</Option>
                      <Option value="ha">Hausa</Option>
                      <Option value="he">Hebrew</Option>
                      <Option value="hi">Hindi</Option>
                      <Option value="hu">Hungarian</Option>
                      <Option value="id">Indonesian</Option>
                      <Option value="ga">Irish</Option>
                      <Option value="it">Italian</Option>
                      <Option value="ja">Japanese</Option>
                      <Option value="kn">Kannada</Option>
                      <Option value="kk">Kazakh</Option>
                      <Option value="rw_RW">Kinyarwanda</Option>
                      <Option value="ko">Korean</Option>
                      <Option value="ky_KG">Kyrgyz (Kyrgyzstan)</Option>
                      <Option value="lo">Lao</Option>
                      <Option value="lv">Latvian</Option>
                      <Option value="lt">Lithuanian</Option>
                      <Option value="mk">Macedonian</Option>
                      <Option value="ms">Malay</Option>
                      <Option value="ml">Malayalam</Option>
                      <Option value="mr">Marathi</Option>
                      <Option value="nb">Norwegian</Option>
                      <Option value="fa">Persian</Option>
                      <Option value="pl">Polish</Option>
                      <Option value="pt_BR">Portuguese (BR)</Option>
                      <Option value="pt_PT">Portuguese (POR)</Option>
                      <Option value="pa">Punjabi</Option>
                      <Option value="ro">Romanian</Option>
                      <Option value="ru">Russian</Option>
                      <Option value="sr">Serbian</Option>
                      <Option value="sk">Slovak</Option>
                      <Option value="sl">Slovenian</Option>
                      <Option value="es">Spanish</Option>
                      <Option value="es_AR">Spanish (ARG)</Option>
                      <Option value="es_ES">Spanish (SPA)</Option>
                      <Option value="es_MX">Spanish (MEX)</Option>
                      <Option value="sw">Swahili</Option>
                      <Option value="sv">Swedish</Option>
                      <Option value="ta">Tamil</Option>
                      <Option value="te">Telugu</Option>
                      <Option value="th">Thai</Option>
                      <Option value="tr">Turkish</Option>
                      <Option value="uk">Ukrainian</Option>
                      <Option value="ur">Urdu</Option>
                      <Option value="uz">Uzbek</Option>
                      <Option value="vi">Vietnamese</Option>
                      <Option value="zu">Zulu</Option>
                    </Select>
                  </Form.Item>
                </div>
                <Form.Item label="Template Type">
                  <Select
                    value={formData.templateType}
                    size="large"
                    onChange={(value) =>
                      handleFieldChange("templateType", value)
                    }
                  >
                    <Option value="Standard Text Only">
                      Standard Text Only
                    </Option>
                    <Option value="Media and interactive">
                      Media and interactive
                    </Option>
                  </Select>
                </Form.Item>
                {formData.templateType === "Media and interactive" && (
                  <div>
                    <Form.Item label="Show/Hide Fields">
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        // defaultValue={[
                        //   "Header",
                        //   "Message Body",
                        //   "Footer",
                        //   "Buttons",
                        // ]}
                        onChange={(values) => {
                          // Toggle the visibility of respective fields based on the selected checkboxes
                          handleFieldChange(
                            "showHeader",
                            values.includes("Header")
                          );
                          handleFieldChange(
                            "showMessageBody",
                            values.includes("Message Body")
                          );
                          handleFieldChange(
                            "showFooter",
                            values.includes("Footer")
                          );
                          handleFieldChange(
                            "showButtons",
                            values.includes("Buttons")
                          );
                        }}
                      >
                        <Row>
                          <Checkbox value="Header">Header</Checkbox>
                          <Checkbox value="Message Body">Message Body</Checkbox>
                          <Checkbox value="Footer">Footer</Checkbox>
                          <Checkbox value="Buttons">Buttons</Checkbox>
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>

                    {formData.showHeader && (
                      <Form.Item label="Header">
                        <Select
                          size="large"
                          onChange={(value) =>
                            handleFieldChange("headerType", value)
                          }
                        >
                          <Option value="text">Text</Option>
                          <Option value="image">Image</Option>
                          <Option value="video">Video</Option>
                          {/* <Option value="document" disabled>
                            Document
                          </Option> */}
                          {/* <Option value="location" disabled>
                            Location
                          </Option> */}
                        </Select>
                        {formData.headerType === "text" && (
                          <Input
                            value={formData.header}
                            style={{ marginTop: "10px" }}
                            size="large"
                            onChange={(e) =>
                              handleFieldChange("header", e.target.value)
                            }
                            placeholder="Header Text"
                            maxLength={60}
                          />
                        )}
                        {formData.headerType === "image" && (
                          <div>
                            <img
                              src={noImage} // Replace with your placeholder image URL
                              alt="Image Placeholder"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                        )}
                        {formData.headerType === "document" && (
                          <div>
                            <img
                              src={document} // Replace with your placeholder image URL
                              alt="Document Placeholder"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                        )}

                        {formData.headerType === "video" && (
                          <div>
                            <img
                              src={video} // Replace with your placeholder image URL
                              alt="Video Placeholder"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                        )}

                        {formData.headerType === "location" && (
                          <div>
                            <img
                              src={location} // Replace with your placeholder image URL
                              alt="Location Placeholder"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                        )}
                      </Form.Item>
                    )}

                    {formData.showMessageBody && (
                      <Form.Item label="Message Body">
                        <TextArea
                          value={formData.messageBody}
                          rows={8}
                          onChange={(e) =>
                            handleFieldChange("messageBody", e.target.value)
                          }
                          maxLength={1024}
                        />
                        <div style={{ color: "gray", marginTop: "5px" }}>
                          To make the template dynamic, add variables like this:{" "}
                          {"{{1}}"}, {"{{2}}"}, etc. e.g. - Hello {"{{1}}"},
                          your code will expire in {"{{2}}"} mins.
                        </div>
                      </Form.Item>
                    )}

                    {formData.showFooter && (
                      <Form.Item label="Footer">
                        <Input
                          value={formData.footer}
                          size="large"
                          onChange={(e) =>
                            handleFieldChange("footer", e.target.value)
                          }
                          placeholder="Footer Text"
                          maxLength={60}
                        />
                      </Form.Item>
                    )}

                    {formData.showButtons && (
                      <Form.Item label="Buttons">
                        <Select
                          size="large"
                          onChange={(value) =>
                            handleFieldChange("buttonType", value)
                          }
                        >
                          <Option value="Quick Reply">Quick Reply</Option>
                          <Option value="Call to Action">Call to Action</Option>
                        </Select>
                        {formData.buttonType === "Quick Reply" ? (
                          <div style={{ marginTop: "10px" }}>
                            {quickButtons.map((button, index) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "20px",
                                }}
                                key={index}
                              >
                                <Form.Item
                                  label={`Button label`}
                                  style={{ width: "100%" }}
                                >
                                  <Input
                                    value={button.label}
                                    size="large"
                                    maxLength={20}
                                    onChange={(e) =>
                                      handleQuickButtonChange(
                                        index,
                                        "label",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Button Label"
                                  />
                                </Form.Item>
                                <Button
                                  onClick={() => removeQuickButton(index)}
                                  size="large"
                                >
                                  Remove
                                </Button>
                              </div>
                            ))}
                            <Button onClick={addQuickButton} size="large">
                              Add Quick Button
                            </Button>
                          </div>
                        ) : (
                          <div style={{ marginTop: 10 }}>
                            {callToActionButtons.map((button, index) => (
                              <div key={index}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "20px",
                                  }}
                                >
                                  <Form.Item
                                    label={`Button Label`}
                                    style={{ width: "100%" }}
                                  >
                                    <Input
                                      value={button.label}
                                      size="large"
                                      maxLength={20}
                                      onChange={(e) =>
                                        handleCallToActionButtonChange(
                                          index,
                                          "label",
                                          e.target.value
                                        )
                                      }
                                      placeholder="Button Label"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    label={`Button Type`}
                                    style={{ width: "100%" }}
                                  >
                                    <Select
                                      value={button.type}
                                      size="large"
                                      onChange={(value) =>
                                        handleCallToActionButtonChange(
                                          index,
                                          "type",
                                          value
                                        )
                                      }
                                    >
                                      <Option value="Url">Url</Option>
                                      <Option value="Phone">Phone</Option>
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    label={`Value`}
                                    style={{ width: "100%" }}
                                  >
                                    <Input
                                      value={button.value}
                                      size="large"
                                      onChange={(e) =>
                                        handleCallToActionButtonChange(
                                          index,
                                          "value",
                                          e.target.value
                                        )
                                      }
                                      placeholder="Button Value"
                                    />
                                  </Form.Item>
                                  <Button
                                    onClick={() =>
                                      removeCallToActionButton(index)
                                    }
                                    size="large"
                                  >
                                    Remove
                                  </Button>
                                </div>
                              </div>
                            ))}
                            <Button
                              onClick={addCallToActionButton}
                              size="large"
                            >
                              Add Call to Action Button
                            </Button>
                          </div>
                        )}
                      </Form.Item>
                    )}
                  </div>
                )}
                {formData.templateType === "Standard Text Only" && (
                  <Form.Item label="Message Body">
                    <TextArea
                      value={formData.messageBody}
                      rows={8}
                      maxLength={1024}
                      onChange={(e) =>
                        handleFieldChange("messageBody", e.target.value)
                      }
                    />
                    <div style={{ color: "gray", marginTop: "5px" }}>
                      To make the template dynamic, add variables like this:{" "}
                      {"{{1}}"}, {"{{2}}"}, etc. e.g. - Hello {"{{1}}"}, your
                      code will expire in {"{{2}}"} mins.
                    </div>
                  </Form.Item>
                )}
                <Form.Item>
                  <Button size="large">Discard Changes</Button>{" "}
                  <Button size="large" type="primary" htmlType="submit">
                    Preview & Submit
                  </Button>
                </Form.Item>
              </Form>

              {showPreview && (
                <PreviewPopup
                  formData={formData} // Pass the form data as props to the preview popup
                  onClose={togglePreview} // Pass a function to close the preview popup
                />
              )}
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default CreateTemplate;
