import React, { useState, useContext, useEffect } from "react";
import MainNavigation from "../../shared/menu/MainNavigation";
import { Link } from "react-router-dom";
import {
  Layout,
  Space,
  Table,
  Result,
  Form,
  Row,
  Col,
  Button,
  Spin,
  Statistic,
  Avatar,
  Modal,
  Input,
} from "antd";
import {
  SendOutlined,
  InboxOutlined,
  UserOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { Helmet } from "react-helmet";
import axios from "axios";
const { Content, Sider } = Layout;

function Profile() {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const dataSource = [];

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}profile/${auth.userId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        setProfile(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile();
  }, [auth.token, auth.userId]);

  // const fetchProfile = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BACKEND_URL}profile/${auth.userId}`,
  //       {
  //         headers: { Authorization: `Bearer ${auth.token}` },
  //       }
  //     );
  //     setProfile(response.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "No. of Credits",
      dataIndex: "credits",
      key: "credits",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  const showModal = () => {
    setFormData({ name: profile.name, email: profile.email, password: "" });
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}edit-profile/${auth.userId}`,
        formData,
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      );
      setIsModalVisible(false);
      window.location.reload(); // Reload the page
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Layout hasSider>
      {loading ? <Spin /> : !loading}
      <Helmet>
        <meta charSet="utf-8" />
        <title>WhatsApp Campaigns | C-Edge BigRadar</title>
        <link
          rel="canonical"
          href="https://app.billsubmit.com/create-company"
        />
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div
              style={{ display: "flex", gap: "10px", alignItems: "flex-end" }}
            >
              <h2 style={{ margin: 0 }}>Profile</h2>
            </div>

            <div
              style={{
                marginTop: "40px",
                display: "flex",
                alignItems: "center",
                gap: 20,
              }}
            >
              <Avatar size={64} icon={<UserOutlined />} />
              <div style={{ display: "flex", alignItems: "center", gap: 50 }}>
                <div>
                  <p style={{ color: "#808080" }}>Name</p>
                  <h2>{profile?.name}</h2>
                </div>

                <div>
                  <p style={{ color: "#808080" }}>Email</p>
                  <h2>{profile?.email}</h2>
                </div>
              </div>
            </div>
            <Button
              style={{ marginTop: 40 }}
              onClick={() => showModal()}
              type="primary"
            >
              Edit Profile
            </Button>

            <Modal
              title="Edit Profile"
              visible={isModalVisible}
              onOk={handleSave}
              onCancel={handleCancel}
            >
              <Form layout="vertical">
                <Form.Item label="Name">
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item label="Email">
                  <Input
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item label="Password">
                  <Input.Password
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    iconRender={(visible) =>
                      visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Profile;
